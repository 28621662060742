import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
//
// import Home from "./../pages/Home";
import Example from './../pages/Example';
import LandingPage from './../pages/LandingPage';

// ----------------------------------------------------------------------

export default createBrowserRouter([
  // Home Routes
  {
    path: '/',
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'example',
        element: <Example />,
      },
    ],
  },

  { path: '*', element: <Navigate to="/" replace /> },
]);
