import React from 'react';
import Page from './../components/Page';
import Header from './../components/Header';
import Hero from './../components/Hero';
import Footer from './../components/Footer';

export default function LandingPage() {
  return (
    <Page title="Talentto">
      <Header />
      <Hero />
      <Footer />
    </Page>
  );
}
