import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Drawer, IconButton, List, ListItemButton, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Talentto from '../assets/talentto.svg';

const Header: React.FC = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: '#FFF',
        padding: { xs: '12px', md: '24px 60px' },
        paddingBottom: { lg: '0', xl: '0' },
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: { xs: 'space-between', md: 'space-between' },
          alignItems: 'center',
        }}
      >
        <img src={Talentto} alt="talentto" style={{ maxWidth: '120px', height: 'auto' }} />

        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ display: { xs: 'block', md: 'none' } }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>

        <Button
          variant="outlined"
          href={`mailto:info@talentto.ai`}
          sx={{
            width: { xs: '100%', md: '138px' },
            height: '48px',
            lineHeight: '24px',
            fontStyle: 'Roboto',
            padding: '12px',
            justifyContent: 'center',
            textAlign: 'center',
            marginLeft: { xs: '0', md: 'auto' },
            border: '2px solid var(--blue-gray-900, #0F172A)',
            borderRadius: '8px',
            color: '#000',
            fontWeight: 'bold',
            fontSize: '16px',
            textTransform: 'none',
            display: { xs: 'none', md: 'block' },
          }}
        >
          Contact us
        </Button>

        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
          <List>
            <ListItemButton onClick={toggleDrawer(false)}>
              <ListItemText primary="About" />
            </ListItemButton>
            <ListItemButton href={`mailto:careers@talentto.ai`} onClick={toggleDrawer(false)}>
              <ListItemText primary="Careers" />
            </ListItemButton>
            <ListItemButton href={`mailto:info@talentto.ai`} onClick={toggleDrawer(false)}>
              <ListItemText primary="Contact us" />
            </ListItemButton>
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
