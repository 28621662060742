// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_HOME = "/";

// ----------------------------------------------------------------------

export const PATH_HOME = {
  root: ROOTS_HOME,
  example: path(ROOTS_HOME, "example"),
};
