import React from 'react';
import { Grid, Link, Typography, Box } from '@mui/material';
import Talentto from '../assets/talenttoFooter.svg';

const Footer: React.FC = () => {
  const sections = [
    {
      title: 'Investors',
      links: [{ name: 'Contact us', contact: 'investors@talentto.ai' }],
    },
    {
      title: 'Company',
      links: [
        { name: 'About', contact: '' },
        { name: 'Careers', contact: 'careers@talentto.ai' },
        { name: 'Contact us', contact: 'info@talentto.ai' },
      ],
    },
  ];

  return (
    <footer>
      <Box
        sx={{
          background: '#0F172A',
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          gap: '48px',
          paddingTop: '20px',
          paddingLeft: '65px',
          paddingRight: '65px',
          '@media (max-width: 960px)': {
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'auto',
            paddingLeft: '16px',
            paddingRight: '16px',
          },
        }}
      >
        <Grid item xs={12} md={1} sx={{ '@media (max-width: 960px)': { textAlign: 'left' } }}>
          <img src={Talentto} alt="talentto" />
        </Grid>

        <Grid item xs={12} md={1} sx={{ display: { xs: 'none', md: 'block' } }} />
        <Grid item xs={12} md={1} sx={{ display: { xs: 'none', md: 'block' } }} />

        {sections.map((section, index) => (
          <Grid item xs={12} md={1} key={index} justifyContent="center">
            <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#FFF' }} gutterBottom>
              {section.title}
            </Typography>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {section.links.map((link, linkIndex) => (
                <li key={linkIndex} style={{ marginBottom: '8px' }}>
                  {link.contact ? (
                    <Link
                      href={`mailto:${link.contact}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="none"
                      sx={{ color: '#FFF' }}
                    >
                      {link.name}
                    </Link>
                  ) : (
                    <span style={{ color: '#FFF' }}>{link.name}</span>
                  )}
                </li>
              ))}
            </ul>
          </Grid>
        ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          background: '#0F172A',
          borderTop: '1px solid var(--blue-gray-700, #334155)',
          padding: {
            xs: '24px 16px',
            md: '24px 80px',
            xl: '24px 65px',
          },
        }}
      >
        <Typography color="#FFF" textAlign="start">
          © 2023 Talentto Inc. | All rights reserved.
        </Typography>
      </Box>
    </footer>
  );
};

export default Footer;
