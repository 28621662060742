import React from "react";
// @mui
import { Box, Typography } from "@mui/material";

// ----------------------------------------------------------------------

export default function Example() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        Section Example
      </Typography>
    </Box>
  );
}
