import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import Connect from '../assets/Connect.svg';
import Company1 from '../assets/company1.svg';
import Company2 from '../assets/company2.svg';
import Company3 from '../assets/company3.svg';
import Company4 from '../assets/company4.svg';
import Company5 from '../assets/company5.svg';

const Hero: React.FC = () => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      bgcolor="#FFF"
      borderBottom="#0F172A"
      sx={{
        padding: { xs: '24px', md: '80px' },
        [theme.breakpoints.up('lg')]: {
          paddingBottom: '10px',
          paddingTop: '10px',
        },
        [theme.breakpoints.up('xl')]: {
          paddingBottom: '27px',
          paddingTop: '27px',
        },
      }}
    >
      <Box display="flex" flexDirection="column" sx={{ gap: { xs: '24px', md: '32px' } }}>
        <Box>
          <Typography
            sx={{
              fontSize: { xs: '40px', md: '72px' },
              fontWeight: 'bold',
              width: '100%',
              lineHeight: '1.2',
              position: 'relative',
            }}
          >
            Decipher your <br />
            work pulse in real time with AI
          </Typography>
        </Box>

        <Box>
          <Typography
            sx={{ fontSize: { xs: '18px', md: '24px' }, fontWeight: 'bold', width: '95%', lineHeight: '1.5' }}
          >
            Talentto analyzes team sentiment and morale in real-time, offering actionable steps to enhance workplace
            climate and productivity.
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            target="_top"
            rel="noopener noreferrer"
            href={`mailto:info@talentto.ai`}
            sx={{
              width: '100%',
              maxWidth: '220px',
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '24px',
              fontStyle: 'Roboto',
            }}
          >
            Learn More
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'center', md: 'flex-start' }}
          sx={{ gap: { xs: '24px', md: '48px' }, marginTop: '48px' }}
        >
          <Typography
            color="text.secondary"
            sx={{ fontSize: '16px', width: '135px', textAlign: { xs: 'center', md: 'left' } }}
          >
            Trusted by leading companies
          </Typography>
          <Box
            display="flex"
            flexDirection={{ xs: 'row', md: 'row' }}
            alignItems={{ xs: 'center', md: 'flex-start' }}
            sx={{ gap: { xs: '24px', md: '48px' } }}
          >
            <img src={Company1} alt="company1" />
            <img src={Company2} alt="company2" />
            <img src={Company3} alt="company3" />
            <img src={Company4} alt="company4" />
            <img src={Company5} alt="company5" />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: 'none', md: 'none', lg: 'block' },
          marginLeft: 'auto',
          marginTop: '24px',
          width: '100%',
          maxWidth: '100%',
        }}
      >
        <img src={Connect} alt="connect" style={{ width: '100%', height: 'auto' }} />
      </Box>
    </Box>
  );
};

export default Hero;
